import React from 'react';
import { Section, Layout } from 'components';
import { PressReleases } from 'containers';
import { Link } from 'gatsby';

const NavLink = (props) => {
  if (!props.deactive) {
    return <Link to={props.url}>{props.text}</Link>;
  } else {
    return <span>{props.text}</span>;
  }
};

const PressReleasesPage = (props) => {
  const { location, pageContext } = props;
  const { group, index, first, last, pageCount } = pageContext;

  //pagination
  var pages = [];
  for (var i = index - 3; i <= index + 3; i++) {
    if (i > 0 && i <= pageCount) {
      pages.push(
        <li className={i == index ? 'uk-active' : ''}>
          <NavLink
            deactive={i == index}
            url={i == 1 ? '/press-releases' : `/press-releases/${i}`}
            text={i}
          />
        </li>
      );
    }
  }
  const previousUrl = index - 1 == 1 ? '' : (index - 1).toString();
  const nextUrl = (index + 1).toString();

  return (
    <Layout location={location}>
      <div className='uk-padding-large'></div>
      <Section title={'보도자료'}>
        <PressReleases PressReleases={group} />
        <ul className='uk-pagination uk-flex-center uk-margin'>
          {!first && (
            <li>
              <NavLink url={`/press-releases/${previousUrl}`} text='Previous' />
            </li>
          )}
          {pages}
          {!last && (
            <li>
              <NavLink url={`/press-releases/${nextUrl}`} text='Next' />
            </li>
          )}
        </ul>
      </Section>
    </Layout>
  );
};

export default PressReleasesPage;
